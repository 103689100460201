.header {
  @apply border-b border-tan-light py-4 mb-8;

  @screen lg {
    @apply py-12 mb-12;
  }

  ul {
    li {
      @apply inline-block uppercase text-16 tracking-wide mx-3;

      a {
        @apply text-primary;

        &:hover, &.active {
          @apply text-blue-600;
        }
      }

      @screen md {
        @apply mx-0 ml-8;
      }
    }
  }

  .logo {
    @apply max-w-xs mx-auto mb-2;

    @screen md {
      @apply max-w-full mb-0;
    }
  }
}
