/* ==========================================================================
   Tailwind
   ========================================================================== */
   @tailwind base;
   @tailwind components;
   @tailwind utilities;

/* ==========================================================================
   Base
   ========================================================================== */
   @import './base/layout.scss';
   @import './base/typography.scss';

/* ==========================================================================
   Components
   ========================================================================== */
   @import './components/header.scss';
   @import './components/footer.scss';
