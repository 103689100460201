.footer {
  @apply bg-blue-500 py-4 mt-4 text-center text-14 text-white;

  .logo {
	  @apply max-w-xs mx-auto;
  }

  p {
		@apply mb-0;

		&.copy {
			@apply text-14 opacity-50;
		}

		span {
			@apply opacity-50;
		}
  }

  a {
	  @apply text-white;

	  &:hover {
		  @apply text-blue-100;
	  }
  }

  @screen lg {
    @apply py-12 mt-12;

    .logo {
	    @apply max-w-full;
    }

    p {
	    @apply text-18;
    }
  }
}
