*, *::after, *::before {
  box-sizing: border-box;
}

a, .btn, img, svg, .sidebar, .layout-item, .box {
	@apply transition-all duration-150 ease-in-out;
}

.container {
  @screen lg{
    @apply max-w-screen-lg;
  }
}

.layout {
	@apply flex flex-wrap content-center justify-center -mx-5;

	&-item {
		@apply align-middle px-5;
	}
}

.box {
  @apply h-full p-10 text-white;
  min-height: 300px;

  h2 {
    @apply font-normal text-24 text-white;
  }

  @screen lg {
    @apply py-16 px-20;

    h2 {
      @apply text-30;
    }
  }
}

.aspect-16x9 {
	@apply relative;
  padding-bottom: 56.25%;

  iframe, embed, object, video {
	  @apply absolute inset-0 w-full h-full;
  }
}

.bg-home {
  background: url(/images/house.png) no-repeat center bottom;
  @apply pb-80;
}

.sidebar {
  @apply text-center text-18 h-0 opacity-0 overflow-hidden;

  ul {
    li {
      @apply pb-2 font-medium;

      a {
        @apply text-primary;

        &:hover, &.active {
          @apply text-blue-600;
        }

        &.inactive {
          @apply text-gray;

          &:hover {
            @apply text-blue-600;
          }
        }
      }
    }
  }

  &.open {
    @apply mb-8 pb-4 border-b border-tan-light h-auto opacity-100;
  }

  @screen md {
    @apply text-left;

    ul {
      columns: 2;
      column-gap: 20px;
    }

    &.open {
      @apply pb-8;
    }
  }

  @screen lg {
    @apply text-right sticky top-32 pr-8 h-auto opacity-100;

    ul {
      columns: 1;
    }
  }
}

.profileImage {
  @apply block mb-4 mx-auto;

  @screen md {
    @apply float-right mb-4 ml-4 mt-1;
  }
}