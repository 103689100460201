// GLOBALS
body {
	@apply font-sans text-16 text-primary h-full w-full overflow-x-hidden relative bg-tan;
	//-webkit-font-smoothing: antialiased;
	//-moz-osx-font-smoothing: grayscale;
	//text-rendering: optimizeLegibility;
}

// HEADINGS
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	@apply text-primary font-sans leading-tight w-full;
}

h1, .h1 {
	@apply text-22 text-white text-left p-6 mb-8 tracking-wide flex justify-between items-center;

	span {
    @apply text-center text-24 text-white px-2 ml-8 border border-white rounded leading-none pb-1;
	}

	&.home {
  	@apply uppercase p-0 text-black mb-0 block text-center text-24;

  	span {
    	@apply text-black p-0 ml-4 border-none text-24;
  	}
	}

	@screen lg {
		@apply text-center text-30 mb-12 p-8 block;

		&.home {
  		@apply text-36;

  		span {
    		@apply text-36;
      }
		}
	}
}

h2, .h2 {
	@apply text-20 font-bold;

	@screen md {
		@apply text-24;
	}
}

h3, .h3 {
	@apply text-16 font-bold;

	@screen md {
		@apply text-20;
	}
}

h4, .h4, h5, .h5, h6, .h6 {
	@apply text-14 uppercase tracking-wide font-medium;

	@screen md {
		@apply text-16;
	}
}

// PARAGRAPGHS
p {
  @apply text-14 mb-3 leading-normal;

  @screen md {
	  @apply text-16 mb-6;
  }
}

.text-small p {
	@apply text-14 mb-4;
}

.text-large, .text-large p {
	@apply text-16;

	@screen md {
		@apply text-18;
	}
}

.trimLast {
	*:last-child {
		@apply mb-0;
	}
}

// LINKS
a {
  @apply text-blue-100;

  &:hover {
		@apply text-blue-600;
  }
}

// BUTTONS
.btn {
	@apply inline-block outline-none appearance-none text-primary text-16 text-center leading-none py-4 px-8 opacity-100 border border-ghost;

	&:focus {
		@apply outline-none appearance-none;
	}

	&:hover {
		@apply text-black opacity-75 border-tan;
	}
}

// LISTS
.textContent {
  ul, ol {
    @apply mb-3 ml-8;
    li {
      @apply text-14 list-outside py-1;
    }

    @screen md {
      @apply mb-6;

      li {
        @apply text-16;
      }
    }
  }

  ul {
    li {
      @apply list-disc;
    }
  }

  ol {
    li {
      @apply list-decimal py-2;
    }
  }
}

